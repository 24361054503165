import orgs from './constants/data/orgs.json'
import tovs from './constants/data/tovs.json'


export  function tov_name(pkod, opt) {
    for (const tov of tovs) {
      if (tov['kod'] === pkod) {
        if (opt === 'first') {
          let ms = tov['ntov'].split(' ', 1)
          return ms[0]
        }
        return tov['ntov']
      }
    }
  }
  
export   function org_name(pkod, opt) {
    for (const org of orgs) {
      if (org['kod'] === pkod) {
        if (opt === 'first') {
          let ms = org['norg'].split(' ', 1)
          return ms[0]
        }
        return org['norg']
  
      }
    }
  }