import { useState } from 'react';
import izds from './constants/data/izds.json'
import { tov_name } from './name'
import { org_name } from './name'
import tstrs from './constants/data/tstrs.json'
import './zak_f.css';
import React from 'react';
import { Context } from './Context.js';
function Zak_f_post(props) {
    const zak = React.useContext(Context);
    const [nsort, Setnsort] = useState(1)

    let z_tovs = []; z_tovs.length = 0;
    for (let t = 0; t < tstrs.length; t++) {
        if (tstrs[t].org == zak.post) {
            for (let i = 0; i < izds.length; i++) {
                if (izds[i].kod > 0 && izds[i].kod == tstrs[t].izd) {
                    z_tovs.push({
                        "kolt": tstrs[t].kolt
                        , "tov": tstrs[t].tov
                        , "ntov": tov_name(tstrs[t].tov, '')
                        , "org": izds[i].org
                        , "izd": izds[i].kod
                        , "norg": org_name(izds[i].org)
                        , "sdate": tstrs[t].sdate
                    })
                }
            }
        }
    }


    if (nsort)
        z_tovs.sort(function (a, b) {
            if (nsort == 1)
                if (a.ntov > b.ntov)
                    return 1
                else
                    return -1
            else
                if (nsort == 2)
                    if ((a. izd-b.izd)>0)
                        return 1
                    else
                        return -1
                else
                    if (nsort == 3)
                        if (a.norg > b.norg)
                            return 1
                        else
                            return -1
        });

    //console.log(tstr)

    let srows = z_tovs.map((ar) => {
        return (
            <tr id={"tov" + ar.tov} className="tr_tstr">
                <td width="250px">{ar.ntov}</td>
                <td>{ar.izd}</td>
                <td width="150px">{ar.norg}</td>
                <td>{ar.kolt}</td>
                <td >{ar.sdate}</td>
            </tr>
        )
    })

    return (
        <table >
            <thead>
                <tr>
                    <th colSpan="4">
                        <p>  {org_name(zak.post, ' ')}</p>
                    </th>
                </tr>

                <tr>
                    <th onClick={() => Setnsort(1)}> Комплектация</th>
                    <th onClick={() => Setnsort(2)}>Заказ</th>
                    <th onClick={() => Setnsort(3)}>Клиент</th>
                    <th className="td_num"> Кол-во</th>
                    <th className="td_date">Дата</th>
                </tr>
            </thead>
            <tbody>
                {srows}
            </tbody>
        </table>


    )
}
export default Zak_f_post;