import { useState } from 'react';
import izds from './constants/data/izds.json'
import { tov_name } from './name.js'
import { org_name } from './name.js'
import tstrs from './constants/data/tstrs.json'
import './zak_f.css';
import React from 'react';
import { Context } from './Context.js';
function Zak_f_kod(props) {
    const zak = React.useContext(Context);
    const [nsort, Setnsort] = useState(1)
    const czak = zak.kod

    
    let qstr = tstrs.filter((qstr) => {
        if (qstr.izd === czak) {
            qstr.norg = org_name(qstr.org)
            qstr.ntov = tov_name(qstr.tov, 'first')
            if ("post" in zak && zak.post > 0) {
                if (zak.post == qstr.org) 
                    return qstr
            }
            else
                return qstr
        }
    })
    if (nsort) {

        qstr.sort(function (a, b) {
            if (nsort == 1)
                if (a.sq - b.sq)
                    return 1
                else
                    return -1
            else
                if (nsort == 2)
                    if (a.ntov > b.ntov)
                        return 1
                    else
                        return -1
                else
                    if (nsort == 3)
                        if (a.norg > b.norg)
                            return 1
                        else
                            return -1

        });
    }
    //console.log(tstr)

    let srows = qstr.map((ar) => {
        return (
            <tr id={"tov" + ar.tov} className="tr_tstr">
                <td>{ar.sq}</td>
                <td width="250px">{tov_name(ar.tov, ' ')}</td>
                <td width="150px">{org_name(ar.org, ' ')}</td>
                <td>{ar.kolt}</td>
                <td >{ar.sdate}</td>
            </tr>
        )
    })

    return (


        <table >
            <thead>
                <tr>
                    <th colSpan="5">
                        <span >Заказ {zak.kod} Дата {zak.sdate}</span>
                    </th>
                </tr>
                <tr>
                    <th colSpan="5">
                        <p>  {org_name(zak.org, ' ')}</p>
                    </th>
                </tr>

                <tr>
                    <th onClick={() => Setnsort(1)}> sq</th>
                    <th onClick={() => Setnsort(2)}> Комплектация</th>
                    <th onClick={() => Setnsort(3)}>Поставщик</th>
                    <th className="td_num"> Кол-во</th>
                    <th className="td_date">Дата</th>
                </tr>
            </thead>
            <tbody>
                {srows}
            </tbody>
        </table>


    )
    function close_zak() {
        props.setszak(0)
    }

}


export default Zak_f_kod;

{/* <tr>
    <th colSpan="4">
        <p>  {tov_name(zak.tov, ' ')}</p>
    </th>
</tr>    */}