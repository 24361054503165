import React from 'react';
import './App.css';
import { useState } from 'react';
import Izds_t from './Izds_t.js';
import Zak_f_org from './Zak_f_org.js';
import Zak_f_kod from './Zak_f_kod.js';
import Zak_f_post from './Zak_f_post.js';
import { Context } from './Context.js';
import izds from './constants/data/izds.json';

function App(props) {
  // let { path, url } = useRouteMatch();

  //  alert("a")
  const [sorg, Setsorg] = useState(0)
  const [sizd, Setsizd] = useState({})

  let w_arraw = []
  w_arraw.push('0' + '@' + 'ВСЕ')
  props.izds.forEach((izd) => {
    if ("org" in izd && !w_arraw.includes(izd.org + '@' + izd.norg)) {
      w_arraw.push(izd.org + '@' + izd.norg)
    }
  })

  let clients = w_arraw.map((ar) => {
    return (
      <option key={ar.split("@")[0]} value={ar.split("@")[0]}>{ar.split("@")[1]}</option>
    )
  })



  var w_izds = []
  if (sorg == 0)
    w_izds = props.izds
  else {
    w_izds.length = 0
    w_izds = props.izds.filter(izd => {
      if (sorg == izd.org)
        return izd
    })
  }



  let s_zak = ""
  if (sizd.kod)
    s_zak = <Zak_f_kod />
  else
    if (sizd.org)
      s_zak = <Zak_f_org />
    else
      if (sizd.post)
        s_zak = <Zak_f_post />

  return (
    <div>

      <div>
        <select id="sel_org" name="selorg" onChange={(e) => Setsorg(e.target.value)}>
          {clients}
        </select>
      </div>
      <div style={{ "display": "flex" }}>
        <div className="izds_t" id="izds_t" >
          <Izds_t izds={w_izds} Setsizd={Setsizd} />
        </div>
        <div className="zak_f" id="zak_f" >
          <Context.Provider value={sizd}>
            {s_zak}
          </Context.Provider >
        </div>
      </div>
    </div >
  )

}


export default App;
