import { useState } from 'react';
import izds from './constants/data/izds.json'
import { tov_name } from './name'
import { org_name } from './name'
import tstrs from './constants/data/tstrs.json'
import './zak_f.css';
import React from 'react';
import { Context } from './Context.js';
function Zak_f_org(props) {
    const zak = React.useContext(Context);
    const [nsort, Setnsort] = useState(1)

    let m_tovs = []; m_tovs.length = 0; let z_tovs = []; z_tovs.length = 0;
    for (let i = 0; i < izds.length; i++) {
        if (izds[i].org > 0 && izds[i].org == zak.org) {
            for (let t = 0; t < tstrs.length; t++) {
                if (tstrs[t].izd == izds[i].kod) {
                    if (tstrs[t].tov > 0 && !m_tovs.includes(tstrs[t].tov)) {
                        m_tovs.push(tstrs[t].tov)
                        z_tovs.push({ "kolt": tstrs[t].kolt
                            , "tov": tstrs[t].tov
                            , "ntov": tov_name(tstrs[t].tov, 'first') 
                            , "org": tstrs[t].org
                            , "norg": org_name(tstrs[t].org) 
                            , "sdate": tstrs[t].sdate
                        })
                    }
                    else {
                        for (let z = 0; z < z_tovs.length; z++) {
                            if (z_tovs[z].tov == tstrs[t].tov) {
                                z_tovs[z].kolt=z_tovs[z].kolt+"+"+ tstrs[t].kolt
                                if (z_tovs[z].org!==tstrs[t].org)
                                    z_tovs[z].norg=z_tovs[z].norg+"+"+ org_name(tstrs[t].org)

                                break
                            }
                        }
                    }
                }
            }
        }
    }

    if (nsort) {

        z_tovs.sort(function (a, b) {
            if (nsort == 1)
                if (a.ntov > b.ntov)
                    return 1
                else
                    return -1
            else
                if (nsort == 2)
                    if (a.norg > b.norg)
                        return 1
                    else
                        return -1

        });
    }
    //console.log(tstr)

    let srows = z_tovs.map((ar) => {
        return (
            <tr id={"tov" + ar.tov} className="tr_tstr">
                <td width="250px">{tov_name(ar.tov, ' ')}</td>
                <td width="150px">{org_name(ar.org, ' ')}</td>
                <td>{ar.kolt}</td>
                <td >{ar.sdate}</td>
            </tr>
        )
    })

    return (


        <table >
            <thead>
               
                <tr>
                    <th colSpan="4">
                        <p>  {org_name(zak.org, ' ')}</p>
                    </th>
                </tr>

                <tr>
                    <th onClick={() => Setnsort(1)}> Комплектация</th>
                    <th onClick={() => Setnsort(2)}>Поставщик</th>
                    <th className="td_num"> Кол-во</th>
                    <th className="td_date">Дата</th>
                </tr>
            </thead>
            <tbody>
                {srows}
            </tbody>
        </table>


    )


}


export default Zak_f_org;

