import React from 'react'
import { useState } from 'react';

import './izds_t.css';

function Izds_t(props) {

  const [nsort, Setnsort] = useState(0)
  const [filter, Setfilter] = useState(" ")
  let value


  if (nsort) {

    props.izds.sort(function (a, b) {
      if (nsort == 1)
        if ("post" in a && a.post > 0 && "post" in b && b.post > 0)
          if (a.post > b.post)
            return 1
          else
            if (a.post == b.post)
              if (a.kod > b.kod)
                return 1
              else
                return -1
            else
              return -1
        else
          if (a.kod > b.kod)
            return 1
          else
            return -1
      else
        if (nsort == 2)
          if (a.norg > b.norg)
            return 1
          else
            if (a.norg == b.norg)
              if (a.kod > b.kod)
                return 1
              else
                return -1
            else
              return -1;
        else
          if (nsort == 3)
            if (a.ntov > b.ntov) return 1; else return -1;
          else
            if (nsort == 4)
              if ((a.kolt - b.kolt) > 0) return 1; else return 1; //-1;
            else
              if (nsort == 5)
                if (a.sdate > b.sdate) return 1; else return -1;

    });
  }

  let w_izds = props.izds.filter(function (ar) {
    if (filter === " " || ar.norg.includes(filter))
      return true
    else
      return false
  })


  let srows = w_izds.map((ar) => {
    if (ar.kod)
      if ("post" in ar)
        return (
          <tr id={"post"+ar.post +"_"+"zak" + ar.kod} className="tr_zak_post" onClick={() => props.Setsizd(ar)}>
            <td >{ar.kod}  </td>
            <td width="250px">{ar.norg}  </td>
            <td width="150px">{ar.ntov}  </td>
            <td>{ar.kolt}</td>
            <td >{ar.sdate}</td>
          </tr>
        )
      else
        return (
          <tr id={"org"+ar.org +"_"+"zak" + ar.kod} className="tr_zak" onClick={() => props.Setsizd(ar)}>
            <td >{ar.kod}  </td>
            <td width="250px">{ar.norg}  </td>
            <td width="150px">{ar.ntov}  </td>
            <td>{ar.kolt}</td>
            <td >{ar.sdate}</td>
          </tr>
        )
    else
      if ("org" in ar && ar.org)
        return (
          <tr id={"org" + ar.org} className="tr_org" onClick={() => props.Setsizd(ar)}>

            <td colspan="3" width="250px">{ar.norg}  </td>
            <td>{ar.kolt}</td>
            <td >{ar.sdate}</td>
          </tr>
        )
      else
        if ("post" in ar && ar.post)
          return (
            <tr id={"post" + ar.post} className="tr_post" onClick={() => props.Setsizd(ar)}>

              <td colspan="3" width="250px">{ar.norg}  </td>
              <td>{ar.kolt}</td>
              <td >{ar.sdate}</td>
            </tr>
          )
  })



  return (
    <table  >
      <thead>
        <tr>
          <th colSpan="5">
            <input value={value} onChange={e => { Setfilter(e.target.value) }} />
          </th>
        </tr>
        <tr >
          <th onClick={() => Setnsort(1)}>Заказ  </th>
          <th onClick={() => Setnsort(2)}>Клиент </th>
          <th onClick={() => Setnsort(3)} >Изделие </th>
          <th onClick={() => Setnsort(4)}>Кол-во  </th>
          <th onClick={() => Setnsort(5)}>Дата  </th>
        </tr>
      </thead>
      <tbody >
        {srows}
      </tbody>

    </table>

  )


}
export default Izds_t