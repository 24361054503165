import React from 'react';
//import  {useTable}  from 'react-table'
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { org_name, tov_name } from './name';
import izds from './constants/data/izds.json';
import tstrs from './constants/data/tstrs.json';

for (let i = 0; i < izds.length; i++) {
  izds[i].norg = org_name(izds[i].org)
  izds[i].ntov = tov_name(izds[i].tov, 'first')
}

let m_orgs = []; m_orgs.length = 0; let z_orgs = [{}]; z_orgs.length = 0  // клиенты
for (let i = 0; i < izds.length; i++) {
  if (izds[i].kod > 0 && !m_orgs.includes(izds[i].org)) {
    m_orgs.push(izds[i].org)
    z_orgs.push({
      "kolt": ""
      , "zaks": ""
      , "kod": ""
      , "post": ""
      , "org": izds[i].org
      , "norg": izds[i].norg
    })
  }

  for (let z = 0; z < z_orgs.length; z++)
    if (z_orgs[z].org == izds[i].org) {
      z_orgs[z].zaks = Number(z_orgs[z].zaks) + 1
      z_orgs[z].kolt = Number(z_orgs[z].kolt) + Number(izds[i].kolt)
      break
    }
}



//____________________________________________________
let m_izds = []; m_izds.length = 0; let z_izds = [{}]; z_izds.length = 0
let m_posts = []; m_posts.length = 0; let z_posts = [{}]; z_posts.length = 0  // поставщики
for (let t = 0; t < tstrs.length; t++) {
  if (!m_posts.includes(tstrs[t].org)) {
    m_posts.push(tstrs[t].org)
    z_posts.push({
      "kolt": ""
      , "zaks": ""
      , "kod": ""
      , "org": ""
      , "post": tstrs[t].org
      , "norg": org_name(tstrs[t].org)
    })
  }
  if (!m_izds.includes(tstrs[t].izd + tstrs[t].org)) {
    m_izds.push(tstrs[t].izd + tstrs[t].org)
    for (let i = 0; i < izds.length; i++)
      if (izds[i].kod > 0 && izds[i].kod == tstrs[t].izd) {
        z_izds.push({
          "kolt": izds[i].kolt
          , "kod": izds[i].kod
          , "post": tstrs[t].org
          , "org": izds[i].org
          , "ntov": izds[i].ntov
          , "norg": izds[i].norg
          , "sdate": izds[i].sdate
        })
        break
      }
  }
}
for (let z = 0; z < z_izds.length; z++)
  for (let p = 0; p < z_posts.length; p++)
    if (z_posts[p].post == z_izds[z].post) {
      z_posts[p].zaks = Number(z_posts[p].zaks) + 1
      z_posts[p].kolt = Number(z_posts[p].kolt) + Number(z_izds[z].kolt)
      break
    }


let w_izds = [{}]; w_izds.length = 0

z_orgs.sort(function (a, b) {
  if (a.norg > b.norg)
    return 1
  else
    if (a.norg == b.norg)
      if (a.kod > b.kod)
        return 1
      else
        return -1
    else
      return -1;
})
for (let z = 0; z < z_orgs.length; z++) {  //клиенты 
  if (z_orgs[z].zaks !== 1)
    w_izds.push(z_orgs[z])
  for (let i = 0; i < izds.length; i++)
    if (izds[i].org == z_orgs[z].org)
      w_izds.push(izds[i])
}
z_posts.sort(function (a, b) {
  if (a.norg > b.norg)
    return 1
  else
    return -1
})
for (let z = 0; z < z_posts.length; z++) {
  w_izds.push(z_posts[z])
  // for (let i = 0; i < z_izds.length; i++)
  //   if (z_izds[i].post == z_posts[z].post)
  //     w_izds.push(z_izds[i])
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App izds={w_izds} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

